@import "colors";

.userSuggestionPopper {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 554px;
    z-index: 999999999999999;
    height: 396px;


    .popper {
        z-index: 9999999;
        padding: 22px;
        background-color: #F5F8FF;
        top: 0 !important;
        left: 0 !important;
        transform: translate(0%, 0%) !important;
        position: absolute !important;
        width: 100%; } }

.userDetailWrap {
    max-height: 350px;
    overflow-y: auto; }

.userWrapper {
    display: flex;
    align-items: center;
    margin: 0 0px 0px 0px; }

.userDetail {
    margin-left: 16px; }

.userEmail {
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 16px;
    font-family: "Mulish", sans-serif;
    line-height: 23px;
    font-weight : lighter; }

.userName {
    font-family: "Mulish", sans-serif;
    color: $sharePopper-dk-grey;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
    letter-spacing: 0.18px; }

.userDp {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer; }
